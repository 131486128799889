<template>
  <div>
    <Consulta
        ref="filtroConsulta"
        titulo="Consulta de Dados Pessoais"
        :parametros="paramsFiltro"
        :colunas="headers"
        :ordenacao-colunas="sortBy"
        :consulta-callback="consulta"
    >
      <template v-slot:areaParametros>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.nome"
                dense
                label="Nome"
                name="nomeDadosPessoais"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="paramsFiltro.cpf"
                v-mask="'###########'"
                :counter="11"
                dense
                label="CPF"
                maxlength="11"
                name="cpfDadosPessoais"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.dataNascimento="{ item }">
        {{ tratarData(item.dataNascimento) }}
      </template>
      <template v-slot:acoesMenu="{ item }">
        <v-list-item>
          <v-btn block text @click="editarDadosPessoais(item)">Editar</v-btn>
        </v-list-item>
      </template>
    </Consulta>
    <v-dialog v-if="dialog" v-model="dialog" width="80%" scrollable persistent>
      <v-card>
        <v-card-title class="primary justify-start white--text">
          Atualizar Dados Pessoais
          <v-spacer/>
          <v-btn icon color="white" @click="cancelarEdicaoDadosPessoais">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
              ref="edicaoDadosPessoaisForm"
              @submit.prevent="salvarEdicaoDadosPessoais"
          >
            <DadosPessoaisForm
                v-model="dadosPessoaisEditado"
                :pode-alterar-todos-os-campos="true"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click.prevent="cancelarEdicaoDadosPessoais">
                Cancelar
              </v-btn>
              <v-btn color="primary" type="submit">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import rules from "@/commons/rules";
import dadosPessoaisService from "@/services/dadosPessoais.service";
import DadosPessoaisForm from "@/components/base/forms/DadosPessoaisForm";
import Consulta from "@/components/pages/admin/consultas/Consulta";
import luxon from "@/core/luxon";

export default {
  name: "DadosPessoais",
  components: {Consulta, DadosPessoaisForm},
  data() {
    return {
      dialog: false,
      headers: [
        {text: "Nome", value: "nome", mustSort: true},
        {text: "CPF", value: "cpf"},
        {text: "Data Nascimento", value: "dataNascimento"},
        {text: "Mãe", value: "mae"}
      ],
      sortBy: ["nome"],
      paramsFiltro: {},
      dadosPessoaisEditado: {},
      consulta: dadosPessoaisService.recuperarFiltro
    };
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),
    ...rules,

    tratarData(data){
      return luxon.dataConverter(data).toLocaleString()
    },

    editarDadosPessoais(dadosPessoais) {
      dadosPessoaisService
          .recuperarDadosPessoais(dadosPessoais.oid)
          .then(response => {
            Object.assign(this.dadosPessoaisEditado, response.data);
            this.dialog = true;
          })
          .catch(() => {
          });
    },

    async salvarEdicaoDadosPessoais() {
      if (this.$refs.edicaoDadosPessoaisForm.validate()) {
        let loader = this.$loading.show();

        await dadosPessoaisService
            .atualizarDadosPessoais(this.dadosPessoaisEditado)
            .then(() => {
              this.$refs.filtroConsulta.getDataFromApi();

              this.exibirAviso({
                mensagem: "Dados Pessoais atualizado com sucesso!",
                tipo: "success"
              });
            })
            .catch(error => {
              let mensagem;
              if (error.response.status === 404) {
                mensagem = "Dados Pessoais não encontrado";
              } else if (error.response.status === 409) {
                mensagem = "Não foi possível alterar os dados pessoais";
              }
              this.exibirAviso({
                mensagem: mensagem,
                tipo: "error"
              });
            });

        this.cancelarEdicaoDadosPessoais();

        loader.hide();
      }
    },

    cancelarEdicaoDadosPessoais() {
      this.dialog = false;
      setTimeout(() => {
        this.dadosPessoaisEditado = {};
      }, 500);
    }
  }
};
</script>

<style scoped></style>

import Vue from "vue";

export default {
  recuperarDadosPessoais(oid) {
    return Vue.axios.get("dadosPessoais/" + oid);
  },

  recuperarDadosPessoaisUsuarioLogado() {
    return Vue.axios.get("dadosPessoais/atual");
  },

  atualizarDadosPessoaisUsuarioLogado(dadosPessoais) {
    return Vue.axios.put("dadosPessoais/atual", dadosPessoais);
  },

  recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
    return Vue.axios.post("dadosPessoais/filtro", {
      params: {
        nome: paramsFiltro.nome,
        cpf: paramsFiltro.cpf
      },
      pagina,
      numElementosPagina,
      ordenacao
    });
  },

  atualizarDadosPessoais(dadosPessoais) {
    return Vue.axios.put("dadosPessoais/" + dadosPessoais.oid, dadosPessoais);
  },
};

<template>
  <div>
    <v-card flat>
      <v-card-title class="tituloForm">
        Identificação
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row dense justify="center">
            <v-col cols="12">
              <v-text-field
                  v-model="dadosPessoais.cpf"
                  :disabled="!podeAlterarTodosOsCampos"
                  dense
                  label="CPF"
                  name="dadosPessoaisCpf"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="dadosPessoais.nome"
                  :disabled="!podeAlterarTodosOsCampos"
                  dense
                  label="Nome"
                  name="dadosPessoaisNome"
                  outlined
              ></v-text-field>
              <v-menu
                  v-model="dataNascimentoMenu"
                  :close-on-content-click="false"
                  min-width="290"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :rules="[campoObrigatorioRule]"
                      :value="
                      tratarDataNumerica(dadosPessoais.dataNascimento)
                    "
                      append-icon="mdi-calendar"
                      dense
                      label="Data de Nascimento *"
                      name="dadosPessoaisDataNascimento"
                      outlined
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    :value="
                     tratarDataPickerISO(dadosPessoais.dataNascimento)
                    "
                    locale="pt-br"
                    @input="tratarDataNascimento"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                  v-model="dadosPessoais.mae"
                  :rules="[campoObrigatorioRule, formatoNomeRule]"
                  dense
                  label="Mãe *"
                  name="dadosPessoaisMae"
                  outlined
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  v-model="dadosPessoais.pai"
                  dense
                  label="Pai"
                  name="dadosPessoaisPai"
                  outlined
              ></v-text-field>
              <v-text-field
                  v-model="dadosPessoais.email"
                  :rules="[campoObrigatorioRule, formatoEmailRule]"
                  dense
                  label="Email *"
                  name="dadosPessoaisEmail"
                  outlined
                  validate-on-blur
                  @input="dadosPessoais.email = $event.target.value"
              ></v-text-field>
              <v-select
                  v-model="dadosPessoais.sexo"
                  :items="[
                  { text: 'Masculino', value: 'M' },
                  { text: 'Feminino', value: 'F' }
                ]"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Sexo *"
                  name="dadosPessoaisSexo"
                  outlined
                  validate-on-blur
              ></v-select>
              <v-row
                  v-if="!dadosPessoais.nomeSocial && !requererNomeSocial"
                  dense
                  justify="center"
              >
                <v-dialog width="600" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        outlined
                        small
                    >
                      <v-icon left>mdi-comment-account</v-icon>
                      Requerer Nome Social
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-title class="primary justify-start white--text"
                      >Requerimento de Nome Social
                      </v-card-title>
                      <v-card-text>
                        <v-alert
                            class="mt-3"
                            type="warning"
                            color="info"
                            prominent
                            outlined
                            text
                            dense
                        >
                          <b>O Requerimento de Nome Social </b>
                          é previsto no
                          <a
                              target="_blank"
                              href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2016/decreto/d8727.htm"
                          >Decreto nº 8.727, de 28 de abril de 2016</a
                          >
                          e
                          <b
                          >é exclusivo para os participantes travestis e
                            transexuais</b
                          >
                          que querem ser reconhecidos por um nome pelo qual se
                          identificam socialmente, de acordo com a sua
                          identidade de gênero.
                        </v-alert>
                      </v-card-text>
                      <v-card-actions class="justify-center">
                        <v-btn
                            color="primary"
                            @click="
                            dialog.value = false;
                            requererNomeSocial = true;
                          "
                        >Li e estou ciente
                        </v-btn>
                        <v-btn color="error" @click="dialog.value = false"
                        >Cancelar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-row>
              <v-text-field
                  v-if="requererNomeSocial || dadosPessoais.nomeSocial"
                  v-model="dadosPessoais.nomeSocial"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Nome Social *"
                  name="dadosPessoaisNomeSocial"
                  outlined
                  validate-on-blur
              >
                <template v-slot:append-outer>
                  <v-btn
                      color="error"
                      icon
                      outlined
                      small
                      @click="cancelarNomeSocial"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                      </template>
                      <span>Cancelar Requerimento</span>
                    </v-tooltip>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-title class="tituloForm">
        Identidade
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row dense justify="space-between">
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.identidade.numero"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Identificação *"
                  maxlength="15"
                  name="dadosPessoaisIdentidadeNumero"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.identidade.orgaoEmissor"
                  v-mask="'AAAAAA'"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Orgão Emissor *"
                  maxlength="6"
                  name="dadosPessoaisIdentidadeOrgaoEmissor"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                  v-model="dadosPessoais.identidade.estadoEmissor"
                  :items="estados"
                  :rules="[
                  campoObrigatorioRule(
                    dadosPessoais.identidade.estadoEmissor.nome
                  )
                ]"
                  dense
                  item-text="nome"
                  label="Estado *"
                  name="dadosPessoaisIdentidadeEstadoEmissor"
                  outlined
                  return-object
                  validate-on-blur
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                  v-model="dataEmissaoMenu"
                  :close-on-content-click="false"
                  min-width="290"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :rules="[campoObrigatorioRule]"
                      :value="
                      tratarDataNumerica(dadosPessoais.identidade.dataEmissao)
                    "
                      append-icon="mdi-calendar"
                      dense
                      label="Data de Emissão *"
                      name="dadosPessoaisIdentidadeDataEmissao"
                      outlined
                      readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                    :value="
                     tratarDataPickerISO(this.dadosPessoais.identidade.dataEmissao)
                    "
                    locale="pt-br"
                    @input="tratarIdentidadeDataEmissao"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-title class="tituloForm"> Origens</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row dense justify="space-between">
            <v-col cols="6">
              <v-select
                  v-model="dadosPessoais.nacionalidadeTipo"
                  :items="tiposNacionalidade"
                  :rules="[
                  campoObrigatorioRule(
                    dadosPessoais.nacionalidadeTipo.descricao
                  )
                ]"
                  dense
                  item-text="descricao"
                  item-value="descricao"
                  label="Nacionalidade *"
                  outlined
                  return-object
                  validate-on-blur
                  @input="tratarTipoNacionalidade"
              ></v-select>
              <v-select
                  v-model="dadosPessoais.nacionalidadePais"
                  :disabled="isBrasileiroNato"
                  :items="paises"
                  :rules="[
                  campoObrigatorioRule(dadosPessoais.nacionalidadePais.nome)
                ]"
                  dense
                  item-text="nome"
                  item-value="nome"
                  label="País *"
                  outlined
                  return-object
                  validate-on-blur
                  @input="tratarPais"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                  v-model="dadosPessoais.naturalidadeEstado"
                  :disabled="!isBrasileiroNato"
                  :items="estados"
                  :rules="
                  isBrasileiroNato
                    ? [
                        campoObrigatorioRule(
                          dadosPessoais.naturalidadeEstado.nome
                        )
                      ]
                    : []
                "
                  dense
                  item-text="nome"
                  item-value="nome"
                  label="Estado *"
                  outlined
                  return-object
                  validate-on-blur
                  @input="dadosPessoais.naturalidadeMunicipio = {}"
              ></v-select>
              <v-select
                  v-model="dadosPessoais.naturalidadeMunicipio"
                  :disabled="!isBrasileiroNato || !isEstadoPreenchido"
                  :items="municipiosPorEstado"
                  :rules="
                  isBrasileiroNato
                    ? [
                        campoObrigatorioRule(
                          dadosPessoais.naturalidadeMunicipio.nome
                        )
                      ]
                    : []
                "
                  dense
                  item-text="nome"
                  label="Cidade *"
                  outlined
                  return-object
                  validate-on-blur
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-title class="tituloForm"> Endereço</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row dense justify="space-between">
            <v-col cols="6">
              <v-select
                  v-model="dadosPessoais.endereco.tipoEndereco"
                  :items="tiposEndereco"
                  :rules="[
                  campoObrigatorioRule(
                    dadosPessoais.endereco.tipoEndereco.descricao
                  )
                ]"
                  dense
                  item-text="descricao"
                  label="Tipo Endereço *"
                  outlined
                  return-object
                  validate-on-blur
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.endereco.cep"
                  v-mask="'########'"
                  :counter="8"
                  :loading="cepLoading"
                  :rules="[campoObrigatorioRule, tamanhoCEPRule]"
                  dense
                  label="CEP *"
                  maxlength="8"
                  name="dadosPessoaisCep"
                  outlined
                  validate-on-blur
              >
                <template v-slot:progress>
                  <v-progress-linear
                      absolute
                      color="accent"
                      height="2"
                      indeterminate
                  ></v-progress-linear>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="primary" icon outlined small @click="buscarCEP">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                      </template>
                      <span>Buscar</span>
                    </v-tooltip>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.endereco.logradouro"
                  :rules="[campoObrigatorioRule, tamanhoMaximoRule(84)]"
                  dense
                  label="Logradouro *"
                  name="dadosPessoaisEnderecoLogradouro"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.endereco.numero"
                  :rules="[campoObrigatorioRule, tamanhoMaximoRule(14)]"
                  dense
                  label="Número *"
                  name="dadosPessoaisEnderecoNumero"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.endereco.complemento"
                  :rules="[tamanhoMaximoRule(58)]"
                  dense
                  label="Complemento"
                  name="dadosPessoaisEnderecoComplemento"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                  v-model="dadosPessoais.endereco.estado"
                  :items="estados"
                  :rules="[campoObrigatorioRule]"
                  dense
                  item-text="nome"
                  item-value="nome"
                  label="Estado *"
                  name="dadosPessoaisEnderecoEstado"
                  outlined
                  validate-on-blur
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.endereco.cidade"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Cidade *"
                  name="dadosPessoaisEnderecoCidade"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="dadosPessoais.endereco.bairro"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Bairro *"
                  name="dadosPessoaisEnderecoBairro"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-title class="tituloForm">
        Telefones
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row
              v-for="(telefone, i) in dadosPessoais.telefones"
              :key="i"
              dense
              justify="space-between"
          >
            <v-col xl="2">
              <v-select
                  v-model="telefone.tipoTelefone"
                  :items="tiposTelefone"
                  :rules="[campoObrigatorioRule(telefone.tipoTelefone.descricao)]"
                  dense
                  item-text="descricao"
                  label="Tipo *"
                  outlined
                  return-object
                  validate-on-blur
              ></v-select>
            </v-col>
            <v-col xl="1">
              <v-text-field
                  v-model="telefone.ddi"
                  v-mask="'##'"
                  :rules="[campoObrigatorioRule, tamanhoRule(2)]"
                  dense
                  label="DDI *"
                  max="2"
                  name="dadosPessoaisTelefoneDDI"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col xl="1">
              <v-text-field
                  v-model="telefone.ddd"
                  v-mask="'##'"
                  :rules="[campoObrigatorioRule, tamanhoRule(2)]"
                  dense
                  label="DDD *"
                  max="2"
                  name="dadosPessoaisTelefoneDDD"
                  outlined
                  validate-on-blur
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                  v-model="telefone.telefoneNumero"
                  v-mask="'#########'"
                  :rules="[campoObrigatorioRule]"
                  dense
                  hint="Somente números"
                  label="Número *"
                  max="9"
                  name="dadosPessoaisTelefoneNumero"
                  outlined
                  validate-on-blur
              >
                <template
                    v-if="dadosPessoais.telefones.length > 1"
                    v-slot:append-outer
                >
                  <v-btn
                      color="error"
                      icon
                      outlined
                      small
                      @click="deletarTelefone(telefone)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                      </template>
                      <span>Apagar</span>
                    </v-tooltip>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
              v-if="dadosPessoais.telefones.length < 3"
              dense
              justify="center"
          >
            <v-btn color="primary" outlined small @click="adicionarTelefone">
              <v-icon left>mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import rules from "@/commons/rules";
import {mapActions} from "vuex";
import telefoneService from "@/services/telefone.service";
import ufService from "@/services/uf.service";
import municipioService from "@/services/municipio.service";
import paisService from "@/services/pais.service";
import tipoNacionalidadeService from "@/services/tipoNacionalidade.service";
import tipoEnderecoService from "@/services/tipoEndereco.service";
import tipoTelefoneService from "@/services/tipoTelefone.service";
import cepService from "@/services/cep.service";
import luxon from "@/core/luxon";
import cepPromise from "@/core/ceppromise";

export default {
  name: "DadosPessoaisForm",

  props: {
    value: {type: Object, required: true},
    podeAlterarTodosOsCampos: {type: Boolean, required: true}
  },

  data() {
    return {
      cepx: undefined,
      cepApi: undefined,
      cepEncontrado: undefined,
      dadosPessoais: {
        ...{
          identidade: {
            dataEmissao: undefined,
            estadoEmissor: {}
          },
          naturalidadeEstado: {},
          naturalidadeMunicipio: {},
          nacionalidadePais: {},
          nacionalidadeTipo: {},
          endereco: {
            pais: {
              nome: "Brasil"
            },
            tipoEndereco: {}
          },
          telefones: [
            {
              ddi: "55",
              tipoTelefone: {}
            }
          ]
        },
        ...this.value
      },
      estados: [],
      municipios: [],
      paises: [],
      tiposNacionalidade: [],
      tiposEndereco: [],
      tiposTelefone: [],
      cepLoading: false,
      requererNomeSocial: false,
      dataEmissaoMenu: false,
      dataNascimentoMenu: false
    };
  },

  watch: {
    dadosPessoais: {

      handler() {
        this.$emit("input", this.dadosPessoais);
      },
      deep: true
    },

    'dadosPessoais.endereco.cep': function(novoCep) {
      if(novoCep.length === 8) {
        this.buscarCEP();
      }
    }
  },

  computed: {
    isBrasileiroNato() {
      return (
          this.dadosPessoais.nacionalidadeTipo &&
          this.dadosPessoais.nacionalidadeTipo.descricao === "Brasileiro Nato"
      );
    },

    isPaisBrasil() {
      return (
          this.dadosPessoais.nacionalidadePais &&
          this.dadosPessoais.nacionalidadePais.nome === "Brasil"
      );
    },

    isEstadoPreenchido() {
      return (
          this.dadosPessoais.naturalidadeEstado &&
          this.dadosPessoais.naturalidadeEstado.nome !== null &&
          this.dadosPessoais.naturalidadeEstado.nome !== undefined
      );
    },

    municipiosPorEstado() {
      if (this.dadosPessoais.naturalidadeEstado) {
        return this.municipios.filter(
            municipio =>
                municipio.codigoEstado ===
                this.dadosPessoais.naturalidadeEstado.codigo
        );
      }
      return [];
    }
  },

  async created() {
    let loader = this.$loading.show();

    await ufService
        .recuperarEstados()
        .then(response => {
          this.estados = response.data;
        })
        .catch(() => {
        });

    await municipioService
        .recuperarMunicipios()
        .then(response => {
          this.municipios = response.data;
        })
        .catch(() => {
        });

    await paisService
        .recuperarPaises()
        .then(response => {
          this.paises = response.data;
        })
        .catch(() => {
        });

    await tipoNacionalidadeService
        .recuperarTiposNacionalidade()
        .then(response => {
          this.tiposNacionalidade = response.data;
        })
        .catch(() => {
        });

    await tipoEnderecoService
        .recuperarTiposEndereco()
        .then(response => {
          this.tiposEndereco = response.data;
        })
        .catch(() => {
        });

    await tipoTelefoneService
        .recuperarTiposTelefone()
        .then(response => {
          this.tiposTelefone = response.data;
        })
        .catch(() => {
        });

    loader.hide();
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    cancelarNomeSocial() {
      this.requererNomeSocial = false;
      this.dadosPessoais.nomeSocial = null;
    },

    async buscarCEP() {
      let cep = this.dadosPessoais.endereco.cep;
      if (cep.length === 8) {
        this.cepLoading = true;
        await cepService
            .recuperarCep(cep)
            .then(response => {
              this.cepEncontrado = true;
              this.dadosPessoais.endereco.estado = response.data.uf.nome;
              this.dadosPessoais.endereco.cidade = response.data.cidade.nome;
              this.dadosPessoais.endereco.bairro = response.data.bairro;
              this.dadosPessoais.endereco.logradouro = response.data.logradouro;
            })
            .catch(() => {
              this.cepEncontrado = false;
              this.dadosPessoais.endereco.estado = null;
              this.dadosPessoais.endereco.cidade = null;
              this.dadosPessoais.endereco.bairro = null;
              this.dadosPessoais.endereco.logradouro = null;
            });
        if (!this.cepEncontrado) {
          await this.buscarCepApi(cep).catch(() => {
            this.exibirAviso({
              mensagem: "CEP não encontrado",
              tipo: "error"
            });
          });
        }
        this.cepLoading = false;
      }
    },

    async buscarCepApi(cep) {

      await cepPromise.recuperarCEP(cep).then((response) => {
        this.cepApi = response;
        this.cepApi.logradouro = response.street;
        this.cepApi.bairro = response.neighborhood;
        this.cepApi.localidade = response.city;
        this.cepApi.uf = response.state;
      });


      await cepService.criaCepApi(this.cepApi, cep).then(response => {
          this.dadosPessoais.endereco.estado = response.data.uf.nome;
          this.dadosPessoais.endereco.cidade = response.data.cidade.nome;
          this.dadosPessoais.endereco.bairro = response.data.bairro;
          this.dadosPessoais.endereco.logradouro = response.data.logradouro;
        }).catch(() => {
          throw new Error;
        });

    },

    tratarDataNumerica(data) {
      if (data === undefined) {
        return undefined
      }
      return luxon.dataConverter(data).toLocaleString();
    },

    tratarDataPickerISO(data) {
      if (data === undefined) {
        return undefined
      }
      return luxon.dataConverter(data).toISODate();
    },

    tratarIdentidadeDataEmissao(value) {
      console.log(value);
      this.dadosPessoais.identidade.dataEmissao = luxon.dataConverter(value).toFormat("yyyy-MM-dd TT")

      this.dataEmissaoMenu = false;
    },

    tratarDataNascimento(value) {
      console.log(value);
      this.dadosPessoais.dataNascimento = luxon.dataConverter(value).toFormat("yyyy-MM-dd TT")

      this.dataNascimentoMenu = false;
    },

    tratarTipoNacionalidade() {
      if (this.isBrasileiroNato) {
        this.dadosPessoais.nacionalidadePais = this.paises.find(
            pais => pais.nome === "Brasil"
        );
      } else {
        this.dadosPessoais.nacionalidadePais = {};

        this.dadosPessoais.naturalidadeEstado = {};
        this.dadosPessoais.naturalidadeMunicipio = {};
      }
    },

    tratarPais() {
      if (!this.isPaisBrasil) {
        this.dadosPessoais.naturalidadeEstado = {};
        this.dadosPessoais.naturalidadeMunicipio = {};
      }
    },

    adicionarTelefone() {
      this.dadosPessoais.telefones.push({
        oid: null,
        ddi: "55",
        ddd: null,
        telefoneNumero: null,
        tipoTelefone: {
          descricao: null
        }
      });
    },

    async deletarTelefone(telefone) {
      let loader = this.$loading.show();

      if (telefone.oid) {
        await telefoneService.deletarTelefone(telefone);
      }

      this.dadosPessoais.telefones.splice(
          this.dadosPessoais.telefones.indexOf(telefone),
          1
      );

      loader.hide();
    }
  },

};
</script>
